/* eslint @typescript-eslint/no-explicit-any: 0 */

/* eslint no-unused-vars: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

import TEXT from '@app/core/constants/text';
import { Product } from '@app/core/types/product';
import { Config } from '@app/pages/reminder-setting/containers/ReminderSetting';
import React from 'react';
import { useHistory } from 'react-router';

interface PropsInterface {
  closeModal: () => void;
}

const SuggestAnswerQuestion = (props: PropsInterface) => {
  const { closeModal } = props;
  const history = useHistory();

  const onConfirm = () => {
    history.push('/questionnaire?day=1');
  };

  return (
    <div className="pd-5 pb-8 gift">
      <div className="txt-l txt-left">
        {/* modal title */}
        {/* {TEXT.REMINDER_SETTING.MODAL_CONFIRM.SUGGEST_ANSWER_QUESTION_TITLE} */}
      </div>
      <p className="note txt-m mt-3 txt-left">
        {/* modal note */}
        {TEXT.REMINDER_SETTING.MODAL_CONFIRM.SUGGEST_ANSWER_QUESTION_TEXT}
      </p>
      <div className="my-4" onClick={() => onConfirm()}>
        <button className="btn btn-primary receive-btn">
          {TEXT.REMINDER_SETTING.MODAL_CONFIRM.SUGGEST_ANSWER_QUESTION_BTN}
        </button>
      </div>
      <div className="pt-4">
        <a className="terms" onClick={() => closeModal()}>
          {TEXT.GENERAL.CLOSE}
        </a>
      </div>
    </div>
  );
};

export default SuggestAnswerQuestion;
