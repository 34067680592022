const ACTION_TYPES = {
  SIGN_IN: 'SIGN_IN',
  SIGN_IN_SUCCESS: 'SIGN_IN_SUCCESS',
  SIGN_IN_ERROR: 'SIGN_IN_ERROR',
  GET_ACTIONS_RANDOM_REQUEST: 'GET_ACTIONS_RANDOM_REQUEST',
  GET_ACTIONS_RANDOM_SUCCESS: 'GET_ACTIONS_RANDOM_SUCCESS',
  GET_ACTIONS_RANDOM_FAILURE: 'GET_ACTIONS_RANDOM_FAILURE',
  SHOW_MODAL: 'SHOW_MODAL',
  HIDE_MODAL: 'HIDE_MODAL',
  SHOW_NOT_FOUND: 'SHOW_NOT_FOUND',
  HIDE_NOT_FOUND: 'HIDE_NOT_FOUND',
  SHOW_NOT_SESAMIN: 'SHOW_NOT_SESAMIN',
  HIDE_NOT_SESAMIN: 'HIDE_NOT_SESAMIN',
  GET_PRODUCT_REDUX: 'GET_PRODUCT_REDUX',
  SET_PRODUCT_REDUX: 'SET_PRODUCT_REDUX',
  SET_IS_COMFIRM_PRODUCT_REDUX: 'SET_IS_COMFIRM_PRODUCT_REDUX',
};

export default ACTION_TYPES;
