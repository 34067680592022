import ACTION_TYPES from '@app/core/constants/types';

export const setProductRedux = (payload = {}) => ({
  type: ACTION_TYPES.SET_PRODUCT_REDUX,
  payload,
});

export const setIsConfirmProductRedux = (payload = {}) => ({
  type: ACTION_TYPES.SET_IS_COMFIRM_PRODUCT_REDUX,
  payload,
});

export const getProductRedux = () => ({
  type: ACTION_TYPES.GET_PRODUCT_REDUX,
});
