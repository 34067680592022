import { createReducer } from '@app/core/helpers/reducer-factory';
import ACTION_TYPES from '@core/constants/types';

export type NotSesaminStateType = {
  isOpen: boolean;
  season: number;
  func: () => void;
};

const initialState: NotSesaminStateType = {
  isOpen: false,
  season: 1,
  // tslint:disable-next-line: no-empty
  func: () => {},
};

/* @ts-expect-error */
const showNotSesamin = (state, payload) => {
  return {
    ...state,
    isOpen: true,
    season: payload.season,
    func: payload.func,
  };
};

/* @ts-expect-error */
const hideNotSesamin = state => ({
  ...state,
  isOpen: false,
});

const strategies = {
  [ACTION_TYPES.SHOW_NOT_SESAMIN]: showNotSesamin,
  [ACTION_TYPES.HIDE_NOT_SESAMIN]: hideNotSesamin,
  /* @ts-expect-error */
  __default__: state => state,
};

const notSesaminReducer = createReducer(strategies, initialState);

export default notSesaminReducer;
