import ACTION_TYPES from '@app/core/constants/types';

export const showModal = (payload = {}) => ({
  type: ACTION_TYPES.SHOW_MODAL,
  payload,
});

export const hideModal = (payload = {}) => ({
  type: ACTION_TYPES.HIDE_MODAL,
  payload,
});
