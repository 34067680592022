import { createReducer } from '@app/core/helpers/reducer-factory';
import { ProductChangeType } from '@app/core/interfaces/productChange';
import ACTION_TYPES from '@core/constants/types';

const initialState: ProductChangeType = {
  product: null,
  isConfirm: false,
};

/* @ts-expect-error */
const setProductRedux = (state, payload) => ({ ...state, product: payload });

/* @ts-expect-error */
const setIsComfirmProductRedux = (state, payload) => ({ ...state, isConfirm: payload });

/* @ts-expect-error */
const getProductRedux = state => ({ ...state });

const strategies = {
  [ACTION_TYPES.GET_PRODUCT_REDUX]: getProductRedux,
  [ACTION_TYPES.SET_PRODUCT_REDUX]: setProductRedux,
  [ACTION_TYPES.SET_IS_COMFIRM_PRODUCT_REDUX]: setIsComfirmProductRedux,
  /* @ts-expect-error */
  __default__: state => state,
};

const productReducer = createReducer(strategies, initialState);

export default productReducer;
