import React from 'react';
import TEXT from '@app/core/constants/text';

interface PropsInterface {
  closeModal: () => void;
}

const Error = (props: PropsInterface) => {
  const { closeModal } = props;
  return (
    <>
      <div className="modal-body">{TEXT.API_ERROR.submit_failed}</div>
      <div className="modal-footer" onClick={closeModal}>
        <span>{TEXT.GENERAL.CLOSE_WINDOW}</span>
      </div>
    </>
  );
};

export default Error;
