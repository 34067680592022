/* eslint @typescript-eslint/no-explicit-any: 0 */

/* eslint react-hooks/exhaustive-deps: 0 */
import React, { useEffect } from 'react';
import liff from '@line/liff';
import { environment } from '@config/environment';
import { useHistory } from 'react-router-dom';
import Modal from './shared/components/modal/containers/Modal';
import CryptoJS from 'crypto-js';
import { UserStatusInterface } from './core/interfaces/userStatus';

const PageRoutes = React.lazy(() => import('./pages/PageRoutes'));

declare let ga: (...param: any) => void;

export default function AppRoutes() {
  /* if else for Features or Auth layout appears */

  // Tracking GA.
  // First come is tracked on index.html, useHistory will be tracked in the next navigation.
  const history = useHistory();
  const [isLoggedInState, setIsLoggedInState] = React.useState(false);
  const [hashUserId, setHashUserId] = React.useState('');

  liff
    .init({ liffId: environment.liffId as string })
    .then(() => {
      setIsLoggedInState(liff.isLoggedIn());
      if (!liff.isLoggedIn()) {
        liff.login({redirectUri: location.href});
      }
      localStorage.setItem('access_token', liff.getAccessToken() as string);
    })
    .catch(err => {
      throw err;
    });

  /**
   * Send LINE User ID to GA
   */
  const sendGATracking = async () => {
    if (hashUserId) {
      ga('create', environment.gaTrackingId, 'auto');
      ga('set', 'page', `${location.pathname}${location.search}`);
      ga('set', 'dimension1', hashUserId);
      ga('send', 'pageview');
    }
  };

  // First access
  useEffect(() => {
    sendGATracking();
  }, [hashUserId]);

  // Handle for navigate
  useEffect(() => {
    return history.listen(() => {
      sendGATracking();
    });
  }, [history, hashUserId]);

  useEffect(() => {
    if (sessionStorage.getItem('maintenance') === 'true') {
      history.push({ pathname: '/release-maintenance' });
    }
  }, []);

  const generateSha512 = (src: string) => {
    const hash = CryptoJS.SHA512(src);
    return hash.toString(CryptoJS.enc.Hex);
  };

  const handleSetHashUserId = (userStatusData: UserStatusInterface) => {
    if (userStatusData.isPccUser) {
      setHashUserId(userStatusData.lineUserHashId as string);
    } else {
      liff.getProfile().then(profile => {
        const temp = `not_pcc_user-${generateSha512(profile.userId)}`;
        setHashUserId(temp);
      });
    }
  };

  return (
    <main className="wrapper">
      {isLoggedInState && <PageRoutes handler={handleSetHashUserId} />}
      <Modal />
    </main>
  );
}
