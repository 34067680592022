import { createReducer } from '@app/core/helpers/reducer-factory';
import ACTION_TYPES from '@core/constants/types';

const initialState = {
  modalOpen: false,
};

/* @ts-expect-error */
const showModal = (state, payload) => {
  return {
    ...payload,
    modalOpen: true,
  };
};

/* @ts-expect-error */
const hideModal = (state, payload) => ({
  ...state,
  modalOpen: false,
});

const strategies = {
  [ACTION_TYPES.SHOW_MODAL]: showModal,
  [ACTION_TYPES.HIDE_MODAL]: hideModal,
  /* @ts-expect-error */
  __default__: state => state,
};

const modalReducer = createReducer(strategies, initialState);

export default modalReducer;
