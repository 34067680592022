/* eslint @typescript-eslint/no-explicit-any: 0 */

/* eslint no-unused-vars: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

import TEXT from '@app/core/constants/text';
import { getProduct, setProduct } from '@app/core/helpers/productHelper';
import { Product } from '@app/core/types/product';
import React, { useEffect, useState } from 'react';
import { PRODUCT_ENUM } from '@app/core/enums';
import { ApiService } from '@app/core/services/api.service';
import { useDispatch, useSelector } from 'react-redux';
import { hideNotFound, showNotFound } from '../../not_found/notfound.actions';
import { ResponseErrType } from '@app/core/types/common';
import { setIsConfirmProductRedux, setProductRedux } from '@app/shared/product/product.actions';
import { RootState } from '@app/app.reducers';

interface PropsInterface {
  closeModal: () => void;
}

interface ProductChangeType {
  product: Product;
  isConfirm: boolean;
}

const ChangeProduct = (props: PropsInterface) => {
  const { closeModal } = props;
  const [text, setText] = useState<string>('');
  const [isLoading, setLoading] = useState(false);
  const api = new ApiService();
  const dispatch = useDispatch();
  const productChange: ProductChangeType = useSelector((state: RootState) => state.productReducer);

  const onConfirm = async () => {
    setLoading(true);
    const payload = {
      productId: productChange.product.productId,
    };
    await api
      .post(['/products'], payload)
      .then(res => {
        if ((res as ResponseErrType).errorCode) {
          dispatch(
            showNotFound({
              func: () => {
                dispatch(hideNotFound());
                onConfirm();
              },
            }),
          );
        } else {
          dispatch(setIsConfirmProductRedux(true));
          setProduct(productChange.product);
        }
      })
      .catch(() => {
        dispatch(
          showNotFound({
            func: () => {
              dispatch(hideNotFound());
              onConfirm();
            },
          }),
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const checkTypeProduct = () => {
    return setText(TEXT.PRODUCT.MODAL_CONFIRM.CONTENT);
    // const preProduct = getProduct();
    // if (preProduct?.typeProduct! === productChange.product.typeProduct) {
    //   return setText(TEXT.PRODUCT.MODAL_CONFIRM.CONTENT_SAME);
    // } else if (preProduct?.typeProduct! === PRODUCT_ENUM.SESAMIN) {
    //   return setText(TEXT.PRODUCT.MODAL_CONFIRM.CONTENT_SESAMIN_TO_OTHER);
    // } else {
    //   return setText(TEXT.PRODUCT.MODAL_CONFIRM.CONTENT_OTHER_TO_SESAMIN);
    // }
  };

  useEffect(() => {
    checkTypeProduct();
  }, []);

  useEffect(() => {
    if (productChange.isConfirm === true) {
      dispatch(setProductRedux({}));
      closeModal();
    }
  }, [productChange]);

  return (
    <div className="pd-5 pb-8 gift">
      <div className="txt-l txt-left">
        {/* modal title */}
        {TEXT.PRODUCT.MODAL_CONFIRM.TITLE}
      </div>
      <p className="note txt-m mt-3 txt-left">
        {/* modal note */}
        {text}
      </p>
      <div className="my-4" onClick={() => onConfirm()}>
        <button className="btn btn-primary receive-btn" disabled={isLoading}>
          {isLoading ? <div className="loader mx-auto"></div> : TEXT.PRODUCT.MODAL_CONFIRM.CONFIRM_MOVE_CHANGE}
        </button>
      </div>
      <div className="pt-4">
        <a className="terms" onClick={() => closeModal()}>
          {TEXT.GENERAL.CLOSE}
        </a>
      </div>
    </div>
  );
};

export default ChangeProduct;
