/* eslint @typescript-eslint/no-explicit-any: 0 */

export function changeText(text: string, ...others: Array<string | number>) {
  let textTemp = text;
  others.forEach((result, index: number) => {
    textTemp = textTemp.replace(`{value${index}}`, `${result}`);
  });
  return textTemp;
}
// next time, considering of replacing ...others by an object

export function getDiffdays(currentDate: string, startDate: string) {
  const diffMilliseconds = Math.abs(
    new Date(currentDate).setHours(0, 0, 0, 0) - new Date(startDate).setHours(0, 0, 0, 0),
  );
  return diffMilliseconds / (1000 * 3600 * 24) + 1;
}

export const switchTab = (tabNumber: number) => {
  const tabHeader = document.getElementsByClassName('tab-header-item');
  const tabContents = document.getElementsByClassName('tab-content-item');
  Array.from(tabContents).forEach((el, i) => {
    el.classList.remove('d-block');
    tabHeader[i]?.classList.remove('active');
  });
  /* @ts-expect-error */
  window.history.replaceState({}, null, `${location.pathname}${location.search}#${tabNumber}`);
  /* @ts-expect-error */
  document.getElementById(`tab-content-item-${tabNumber}`).classList.add('d-block');
  /* @ts-expect-error */
  document.getElementById(`tab-header-item-${tabNumber}`).classList.add('active');
};

export const initialTab = (initialVal: number | string) => {
  const hash = window.location.hash ? window.location.hash.substr(1) : initialVal;
  document.getElementById(`tab-content-item-${hash}`)?.classList.add('d-block');
  document.getElementById(`tab-header-item-${hash}`)?.classList.add('active');
};

export const formatDate = (datetime: string | number, showTime: boolean = false) => {
  const date = new Date(datetime);
  const d = date.getUTCDate();
  const m = date.getUTCMonth() + 1;
  const y = date.getUTCFullYear();
  if (showTime) {
    const h = date.getUTCHours();
    const min = date.getUTCMinutes();
    return `${y}/${m}/${d} ${h < 10 ? `0${h}` : h}:${min < 10 ? `0${min}` : min}`; // YYYY/M/D H:M
  } else {
    return `${y}/${m}/${d}`; // YYYY/M/D
  }
};

export const groupBy = function (xs: any[], key: string) {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};
