/* eslint @typescript-eslint/no-explicit-any: 0 */

/* eslint no-unused-vars: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

import TEXT from '@app/core/constants/text';
import { MODAL_ENUM } from '@app/core/enums';
import { ApiService } from '@app/core/services/api.service';
import { ResponseErrType } from '@app/core/types';
import { Config } from '@app/pages/reminder-setting/containers/ReminderSetting';
import { showModal } from '@app/shared/components/modal/modal.actions';
import liff from '@line/liff';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

interface PropsInterface {
  state: {
    payload: {
      config: Config;
    };
  };
  closeModal: () => void;
}

const Remind = (props: PropsInterface) => {
  const { state, closeModal } = props;

  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);

  const api = new ApiService();

  const updateConfig = () => {
    const body = state.payload.config;
    body[1].status = !body[1].status;

    api
      .post(['/users/config'], body)
      .then(res => {
        if ((res as ResponseErrType).errorCode) {
          dispatch(showModal({ type: MODAL_ENUM.ERROR }));
        } else {
          liff.closeWindow();
        }
      })
      .catch(() => {
        dispatch(showModal({ type: MODAL_ENUM.ERROR }));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onConfirm = () => {
    updateConfig();
    closeModal();
  };

  return (
    <div className="pd-5 pb-8 gift">
      <div className="txt-l txt-left">
        {state.payload.config[1].status
          ? TEXT.REMINDER_SETTING.MODAL_CONFIRM.TITLE_OFF
          : TEXT.REMINDER_SETTING.MODAL_CONFIRM.TITLE_ON}
      </div>
      <p className="note txt-m mt-3">
        {state.payload.config[1].status
          ? TEXT.REMINDER_SETTING.MODAL_CONFIRM.NOTE_OFF
          : TEXT.REMINDER_SETTING.MODAL_CONFIRM.NOTE_ON}
      </p>
      <div className="my-4" onClick={() => onConfirm()}>
        <button className="btn btn-primary receive-btn" disabled={isLoading}>
          {state.payload.config[1].status
            ? TEXT.REMINDER_SETTING.MODAL_CONFIRM.BTN_OFF
            : TEXT.REMINDER_SETTING.MODAL_CONFIRM.BTN_ON}
        </button>
      </div>
      <div className="pt-4">
        <a className="terms" onClick={() => closeModal()}>
          {TEXT.GENERAL.CLOSE}
        </a>
      </div>
    </div>
  );
};

export default Remind;
