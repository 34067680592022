/* eslint @typescript-eslint/no-explicit-any: 0 */

/* eslint react-hooks/exhaustive-deps: 0 */

import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hideModal } from '@app/shared/components/modal/modal.actions';
import Error from '../contents/Error';
import Gift from '../contents/Gift';
import Remind from '../contents/Remind';
import ChangeProduct from '../contents/ChangeProduct';
import SuggestAnswerQuestion from '../contents/SuggestAnswerQuestion';
import RemindAll from '../contents/RemindAll';
import { MODAL_ENUM } from '@app/core/enums';
import { RootState } from '@app/app.reducers';
import AnswerQuestion from '../contents/AnswerQuestion';

const Modal = () => {
  const [contentCpn, setContentCpn] = useState(null);
  const modalState = useSelector((state: RootState) => state.modalReducer);
  const dispatch = useDispatch();

  const closeModal = useCallback(() => {
    dispatch(hideModal());
  }, [modalState]);

  useEffect(() => {
    if (modalState.modalOpen) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
  }, [modalState.modalOpen]);

  useEffect(() => {
    switch (modalState.type) {
      case MODAL_ENUM.ERROR:
        /* @ts-expect-error */
        setContentCpn(<Error closeModal={closeModal} />);
        break;

      case MODAL_ENUM.GIFT:
        /* @ts-expect-error */
        setContentCpn(<Gift closeModal={closeModal} state={modalState} />);
        break;

      case MODAL_ENUM.REMIND:
        /* @ts-expect-error */
        setContentCpn(<Remind closeModal={closeModal} state={modalState} />);
        break;

      case MODAL_ENUM.CHANGE_PRODUCT:
        /* @ts-expect-error */
        setContentCpn(<ChangeProduct closeModal={closeModal} state={modalState} />);
        break;

      case MODAL_ENUM.SUGGEST_ANSWER_QUESTION:
        /* @ts-expect-error */
        setContentCpn(<SuggestAnswerQuestion closeModal={closeModal} state={modalState} />);
        break;

      case MODAL_ENUM.ANSWER_QUESTION:
        /* @ts-expect-error */
        setContentCpn(<AnswerQuestion closeModal={closeModal} currentSeason={modalState.season} />);
        break;
      
      case MODAL_ENUM.REMIND_ALL:
        /* @ts-expect-error */
        setContentCpn(<RemindAll closeModal={closeModal} state={modalState} />);
        break;
    }
  }, [modalState]);

  return (
    <>
      {modalState.modalOpen && (
        <div className="modal-wrapper" aria-modal={true} aria-hidden={true} tabIndex={-1} role="dialog">
          <div className="modal">{contentCpn}</div>
        </div>
      )}
    </>
  );
};

export default Modal;
