import { createReducer } from '@app/core/helpers/reducer-factory';
import ACTION_TYPES from '@core/constants/types';

export type NotFoundStateType = {
  isOpen: boolean;
  func: () => void;
};

const initialState: NotFoundStateType = {
  isOpen: false,
  // tslint:disable-next-line: no-empty
  func: () => {},
};

/* @ts-expect-error */
const showNotFound = (state, payload) => {
  return {
    ...state,
    isOpen: true,
    func: payload.func,
  };
};

/* @ts-expect-error */
const hideNotFound = state => ({
  ...state,
  isOpen: false,
});

const strategies = {
  [ACTION_TYPES.SHOW_NOT_FOUND]: showNotFound,
  [ACTION_TYPES.HIDE_NOT_FOUND]: hideNotFound,
  /* @ts-expect-error */
  __default__: state => state,
};

const notFoundReducer = createReducer(strategies, initialState);

export default notFoundReducer;
