/* eslint no-unused-vars: 0 */

export enum MODAL_ENUM {
  ERROR = 1,
  GIFT = 2,
  REMIND = 3,
  CHANGE_PRODUCT = 4,
  SUGGEST_ANSWER_QUESTION = 5,
  ANSWER_QUESTION = 6,
  REMIND_ALL = 7,
}
