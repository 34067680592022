import React from 'react';
import TEXT from '@app/core/constants/text';
import { Footer } from '../../layout';
import { useSelector } from 'react-redux';
import { RootState } from '@app/app.reducers';
import { NotSesaminStateType } from '../notsesaminuser.reducers';
import { notPermissionBase64Img } from './NotPermissionBase64Img';
import { useHistory } from 'react-router';

const NotFound = () => {
  const notSesaMinState: NotSesaminStateType = useSelector((state: RootState) => state.notSesaminReducer);
  const history = useHistory();
  React.useEffect(() => {
    if (notSesaMinState.isOpen) {
      document.getElementById('root')?.classList?.add('limit-height');
    } else {
      document.getElementById('root')?.classList?.remove('limit-height');
    }
  });

  const onConfirm = () => {
    history.push(`/questionnaire?day=1&seasonNumber=${notSesaMinState.season}&reAnswer=1`);
    window.location.reload();
  };

  return (
    <>
      {notSesaMinState.isOpen && (
        <div className="not-found-page page-not-sesamin">
          <div className="not-found-body">
            <img className="not-sesamin-user-img" src={notPermissionBase64Img} alt="Not Found" />
            <h4 className="sub-title-not-sesamin">{TEXT.NOT_SESAMIN_USER.DESCRIPTION}</h4>
            <div className="my-4" onClick={() => onConfirm()}>
              <button className="btn btn-primary receive-btn">{TEXT.NOT_SESAMIN_USER.BTN_LABEL}</button>
            </div>
          </div>
          <div className="not-found-footer">
            <Footer />
          </div>
        </div>
      )}
    </>
  );
};

export default NotFound;
