/* eslint @typescript-eslint/no-explicit-any: 0 */

/* eslint no-unused-vars: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

import React from 'react';
import Lottie from 'react-lottie-player';
import TEXT from '@app/core/constants/text';
import animationData from '../../../../../assets/json/gift_popup.json';
import { useHistory } from 'react-router';
import { GIFT_TYPE } from '@app/core/enums';
import { changeText } from '@app/core/helpers/common';
interface PropsInterface {
  state: {
    type: string;
    typeId: string;
    actionType: string;
    reminderTimeMilliseconds: number;
    typeGift: GIFT_TYPE;
    dueDateTimeString: string;
  };
  closeModal: () => void;
}

const Gift = (props: PropsInterface) => {
  const { state, closeModal } = props;
  const history = useHistory();
  const onReceiveGiftClick = () => {
    sessionStorage.setItem('typeId', state.typeId);
    history.push({
      pathname: '/badges/gifts',
      search: `?typeId=${state.typeId}&actionType=${state.actionType}&reminderTimeMilliseconds=${state.reminderTimeMilliseconds}`,
    });
    closeModal();
  };

  return (
    <div className="pd-5 pb-8 gift">
      <div className="txt-l txt-left">
        {state.typeGift === GIFT_TYPE.DEFAULT_GIFT ? TEXT.BADGES.MODAL.CONTENT : TEXT.BADGES.MODAL.LOTTERY_CONTENT}
      </div>
      <p className="date txt-m txt-bold mt-3">
        {changeText(
          TEXT.BADGES.MODAL.DATE,
          new Date(state.dueDateTimeString).getMonth() + 1,
          new Date(state.dueDateTimeString).getDate(),
        )}
      </p>
      <div onClick={() => onReceiveGiftClick()}>
        <div className="flex-center-x pb-5">
          <Lottie loop={true} animationData={animationData} play={true} style={{ width: 180, height: 180 }} />
        </div>
        <button className="btn btn-primary receive-btn">
          {state.typeGift === GIFT_TYPE.DEFAULT_GIFT
            ? TEXT.BADGES.MODAL.RECEIVE_GIFT_BTN
            : TEXT.BADGES.MODAL.RECEIVE_LOTTERY_GIFT_BTN}
        </button>
      </div>
      <div className="pt-4">
        <a className="terms" onClick={() => closeModal()}>
          {TEXT.GENERAL.CLOSE}
        </a>
      </div>
    </div>
  );
};

export default Gift;
