import { Product } from '@app/core/types/product';

export const setProduct = (product: Product): void => {
  if (localStorage) {
    localStorage.setItem('product', JSON.stringify(product));
  }
};

export const getProduct = (): Product | null => {
  if (localStorage) {
    const product = localStorage.getItem('product');
    return product && product !== 'undefined' ? JSON.parse(product) : null;
  }

  return null;
};
