import React from 'react';
import TEXT from '@app/core/constants/text';

export function Footer() {
  return (
    <footer>
      <div className="container">
        <div className="info">
          <a className="terms" href={'/static/terms.html'}>
            {TEXT.FOOTER.TERMS}
          </a>
          <a className="terms" href={'/static/privacy-policy.html'}>
            {TEXT.FOOTER.PRIVACY_POLICY}
          </a>
        </div>
        <p className="copyright" dangerouslySetInnerHTML={{ __html: TEXT.FOOTER.COPYRIGHT1 }} />
        <p className="copyright">{TEXT.FOOTER.COPYRIGHT2}</p>
      </div>
    </footer>
  );
}
