import { createReducer } from '@app/core/helpers/reducer-factory';
import ACTION_TYPES from '@core/constants/types';

const initialState = {
  data: null,
  isFetching: false,
  isProcessing: false,
  hasError: false,
  error: null,
};

/* @ts-expect-error */
const getActionsRandomRequest = (state, payload) => ({
  ...state,
  isFetching: true,
});

/* @ts-expect-error */
const getActionsRandomSuccess = (state, payload) => ({
  ...state,
  isFetching: false,
  data: payload,
});

/* @ts-expect-error */
const getActionsRandomFailure = (state, payload) => ({
  ...state,
  isFetching: false,
  hasError: true,
  error: payload.message,
});

const strategies = {
  [ACTION_TYPES.GET_ACTIONS_RANDOM_REQUEST]: getActionsRandomRequest,
  [ACTION_TYPES.GET_ACTIONS_RANDOM_SUCCESS]: getActionsRandomSuccess,
  [ACTION_TYPES.GET_ACTIONS_RANDOM_FAILURE]: getActionsRandomFailure,
  /* @ts-expect-error */
  __default__: state => state,
};

const actionsRandomReducer = createReducer(strategies, initialState);

export default actionsRandomReducer;
