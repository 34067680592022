import { put, takeLatest } from 'redux-saga/effects';
import { getActionsRandomSuccess, getActionsRandomFailure } from './action.actions';
import { ApiService } from '@app/core/services/api.service';
import ACTION_TYPES from '@core/constants/types';

const api = new ApiService();
/* @ts-expect-error */
export function* getActionsRandom(action) {
  try {
    /* @ts-expect-error */
    const res = yield api.get([
      `/actions/randoms?actionId=${action.actionId}&categoryId=${action.categoryId}&milliseconds=${action.milliseconds}`,
    ]);
    yield put(getActionsRandomSuccess(res));
  } catch (err) {
    yield put(getActionsRandomFailure(err));
  }
}

export function* watchGetActionsRandom() {
  yield takeLatest(ACTION_TYPES.GET_ACTIONS_RANDOM_REQUEST, getActionsRandom);
}
