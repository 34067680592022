import { combineReducers } from 'redux';
import actionsRandomReducer from '@app/pages/actions/action.reducers';
import modalReducer from '@app/shared/components/modal/modal.reducers';
import notFoundReducer from '@app/shared/components/not_found/notfound.reducers';
import notSesaminReducer from './shared/components/not_sesamin_user/notsesaminuser.reducers';
import productReducer from './shared/product/product.reducers';

const appReducer = combineReducers({
  actionsRandomReducer,
  modalReducer,
  notFoundReducer,
  notSesaminReducer,
  productReducer,
});

export default appReducer;

export type RootState = ReturnType<typeof appReducer>;
