export const environment = {
  envName: process.env.ENVIRONMENT_NAME,
  apiBaseUrl: process.env.API_BASE_URL,
  liffId: process.env.LIFF_ID,
  lineOAId: process.env.LINE_OA_ID,
  gaTrackingId: process.env.GA_TRACKING_ID,
  resourceUrl: process.env.BASE_RESOURCE_URL,
  forgotPasswordUrl: process.env.FORGOT_PASSWORD_URL,
  webappUrl: process.env.WEBAPP_URL,
};
