import ACTION_TYPES from '@app/core/constants/types';

export const showNotFound = (payload = {}) => ({
  type: ACTION_TYPES.SHOW_NOT_FOUND,
  payload,
});

export const hideNotFound = () => ({
  type: ACTION_TYPES.HIDE_NOT_FOUND,
});
