import React from 'react';
import TEXT from '@app/core/constants/text';
import { notFoundBase64Img } from './NotFoundBase64Img';
import { Footer } from '../../layout';
import { useSelector } from 'react-redux';
import { RootState } from '@app/app.reducers';
import { NotFoundStateType } from '../notfound.reducers';

const NotFound = () => {
  const notFoundState: NotFoundStateType = useSelector((state: RootState) => state.notFoundReducer);
  React.useEffect(() => {
    if (notFoundState.isOpen) {
      document.getElementById('root')?.classList?.add('limit-height');
    } else {
      document.getElementById('root')?.classList?.remove('limit-height');
    }
  });

  const handleRefresh = () => {
    const localStorageItems = ['isSubmittedQuestion', 'isPccUser', 'lineUserHashId'];
    localStorageItems.forEach(item => {
      localStorage.removeItem(item);
    });
    window.location.reload();
  };

  return (
    <>
      {notFoundState.isOpen && (
        <div className="not-found-page">
          <div className="not-found-body">
            <h1 className="page-title">{TEXT.NOT_FOUND.TITLE}</h1>
            <h3 className="sub-title">{TEXT.NOT_FOUND.SUB_TITLE}</h3>
            <img className="error-img" src={notFoundBase64Img} alt="Not Found" />
            <button className="btn" onClick={handleRefresh}>
              {TEXT.NOT_FOUND.BTN_LABEL}
            </button>
          </div>
          <div className="not-found-footer">
            <Footer />
          </div>
        </div>
      )}
    </>
  );
};

export default NotFound;
