import ACTION_TYPES from '@app/core/constants/types';

export const getActionsRandomRequest = (actionId: string, categoryId: string, milliseconds: string) => ({
  type: ACTION_TYPES.GET_ACTIONS_RANDOM_REQUEST,
  actionId,
  categoryId,
  milliseconds,
});

/* @ts-expect-error */
export const getActionsRandomSuccess = payload => ({
  type: ACTION_TYPES.GET_ACTIONS_RANDOM_SUCCESS,
  payload,
});

/* @ts-expect-error */
export const getActionsRandomFailure = payload => ({
  type: ACTION_TYPES.GET_ACTIONS_RANDOM_FAILURE,
  payload,
});
