/* eslint @typescript-eslint/no-explicit-any: 0 */

/* eslint no-unused-vars: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

import React from 'react';
import TEXT from '@app/core/constants/text';
import { useHistory } from 'react-router';

interface PropsInterface {
  closeModal: () => void;
  currentSeason: number;
}

const AnswerQuestion = (props: PropsInterface) => {
  const { closeModal, currentSeason } = props;
  const history = useHistory();
  const onConfirm = () => {
    history.push(`/questionnaire?day=1&seasonNumber=${currentSeason}&reAnswer=1`);
  };

  return (
    <div className="pd-5 pb-8 gift">
      <div className="txt-l txt-left">
        {/* modal title */}
        {TEXT.RESULT_ANSWER.MODAL.TITLE}
      </div>
      <p className="note txt-m mt-3 txt-left">
        {/* modal note */}
        {TEXT.RESULT_ANSWER.MODAL.DESCRIPTION}
      </p>
      <div className="my-4" onClick={() => onConfirm()}>
        <button className="btn btn-primary receive-btn">{TEXT.RESULT_ANSWER.MODAL.BTN_SUBMIT}</button>
      </div>
      <div className="pt-4">
        <a className="terms" onClick={() => closeModal()}>
          {TEXT.RESULT_ANSWER.MODAL.BTN_CLOSE}
        </a>
      </div>
    </div>
  );
};

export default AnswerQuestion;
