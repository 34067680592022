/* eslint react-hooks/exhaustive-deps: 0 */

import React, { Suspense, useEffect } from 'react';
import * as ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider, useDispatch } from 'react-redux';
import createSagaMiddleware from 'redux-saga';
import { logger } from 'redux-logger';
import appMiddleware from './app.middleware';
import appReducer from './app.reducers';
import AppRoutes from './AppRoutes';
import { applyMiddleware, createStore } from 'redux';
import NotFound from './shared/components/not_found/containers/NotFound';
import { showNotFound } from './shared/components/not_found/notfound.actions';
import NotSesaminUser from './shared/components/not_sesamin_user/containers/NotSesaminUser';
import { ErrorBoundary } from 'react-error-boundary';

/**
 * ビルド後のJSファイル名を変更するために無意味なコードを挿入する
 * （キャッシュをクリアする目的）
 * 22/4/13以降のリリースでこのコードは削除する
 */
export let DONT_USE_THIS_VARIABLE = 1;
DONT_USE_THIS_VARIABLE += 1;

const middleware = createSagaMiddleware();

const store = createStore(appReducer, applyMiddleware(middleware, logger));

middleware.run(appMiddleware);

function FallbackComponent() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(showNotFound({ func: () => location.reload() }));
  }, []);
  return <></>;
}

const myFallback = <FallbackComponent />;

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <NotFound />
      <NotSesaminUser />
      <Suspense fallback="">
        <ErrorBoundary fallback={myFallback}>
          <AppRoutes />
        </ErrorBoundary>
      </Suspense>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root'),
);
